<template>
  <div id="container">
    <div class="warn-tips">
      <ul>
        <li>1.
          迁移期间无法使用服务器，时间不做补偿处理，具体迁移时间由实例的磁盘大小决定，1TB磁盘大小的实例迁移需要大约耗时1~2天。
        </li>
        <li>2. 每个实例只能进行一次跨机房迁移，即某实例原本在西南一区，迁移到西南二区之后，不能再迁移回到西南一区。</li>
        <li>3. 当前等待迁移实例 <span style="color: blue">{{
            instanceRegionMigrateLogStatistic.waitingCount || 0
          }}</span> 个，
          迁移中实例 <span style="color: blue">{{ instanceRegionMigrateLogStatistic.migratingCount || 0 }}</span> 个，
          迁移完成实例 <span style="color: blue">{{ instanceRegionMigrateLogStatistic.completedCount || 0 }}</span> 个。
        </li>
      </ul>
    </div>
    <el-table :data="instanceRegionMigrateLogs" style="width: 100%;min-height: 768px">
      <el-table-column
          label="源实例"
          prop="sourceInstanceName">
      </el-table-column>
      <el-table-column
          label="创建时间"
          prop="createTime">
      </el-table-column>
      <el-table-column
          label="更新时间"
          prop="updateTime">
      </el-table-column>

      <el-table-column
          label="源机房"
          prop="sourceRegionStr">
      </el-table-column>
      <el-table-column
          label="目标机房"
          prop="targetRegionStr">
      </el-table-column>

      <el-table-column
          label="状态"
          prop="migrateStatusStr">
      </el-table-column>

      <el-table-column align="right">
        <template #header>
          <el-button type="info" size="small" @click="getInstanceRegionMigrateLogs(true)">刷新</el-button>
        </template>
        <template #default="scope">
          <span style="margin-right: 2px">{{ scope.row.remark }}</span>
          <el-button type="warning" size="small" v-show="scope.row.cancelable"
                     @click="handleCancelMigrateRequest(scope.$index, scope.row)">取消申请
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-button style="margin-top: 4px" type="primary" @click="handleClickAddMigrateRequest"
               :disabled="!instanceRegionMigrateSwitch">新增迁移申请
    </el-button>

    <el-dialog title="跨机房迁移申请" v-model="addMigrateRequestDialogFormVisible">
      <el-form :model="addInstanceRegionMigrateRequestForm" label-position="top"
               ref="addInstanceRegionMigrateRequestForm">
        <el-form-item label-width="120px" label="待迁移实例" prop="sourceInstanceName">
          <el-select v-model="addInstanceRegionMigrateRequestForm.sourceInstanceName" placeholder="请选择需要迁移的实例"
                     style="width: 100%">
            <el-option
                v-for="item in instances"
                :key="item.id"
                :label="getMigrateInstanceLabel(item)"
                :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label-width="120px" label="机房配置" prop="region">
          <el-select v-model="addInstanceRegionMigrateRequestForm.targetRegion" placeholder="请选择迁移目标机房"
                     style="width: 100%">
            <el-option
                v-for="item in regionOptionsList"
                :disabled="item.disabled"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="备注" label-width="120px">
          <el-input v-model="addInstanceRegionMigrateRequestForm.remark" type="textarea" maxlength="255"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="addMigrateRequestDialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleDoAddMigrateRequest">确 定
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  commonSettings,
  instanceStatus,
  instanceCloudInitStatusStr,
  instanceCloudInitStatus,
  instanceVolumeStatus,
  serverDomain
} from '@/common/constant'
import {getPlaningStr} from '@/common/format'
import {useStore} from "vuex";
import Throttle from "@/common/throttle";
import * as echarts from 'echarts/core';
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import {LineChart} from 'echarts/charts';
import {UniversalTransition} from 'echarts/features';
import {CanvasRenderer} from 'echarts/renderers';
import store from "@/store";
import intro from "@/views/Intro";
import {ElMessage, ElNotification} from "element-plus";

echarts.use([GridComponent, TitleComponent, ToolboxComponent, TooltipComponent, LegendComponent,
  LineChart, UniversalTransition, CanvasRenderer]);

export default {
  name: "InstanceRegionMigrateLog",
  inject: ['reload'],
  data() {
    return {
      instances: [],
      regionOptionsList: [],

      instanceRegionMigrateSwitch: null,
      addMigrateRequestDialogFormVisible: false,
      addInstanceRegionMigrateRequestForm: {
        sourceInstanceName: null,
        targetRegion: null,
        remark: null
      },
      instanceRegionMigrateLogs: [],
      instanceRegionMigrateLogStatistic: {
        waitingCount: 0,
        migratingCount: 0,
        completedCount: 0
      }
    }
  },
  mounted() {
    this.store = useStore();
    this.getInstanceRegionMigrateLogStatistic();
    this.getInstanceRegionMigrateLogs(false);
    this.getInstanceRegionMigrateSwitch();
  },

  methods: {
    getInstanceList(isRefresh) {
      this.$httpUtil.get('/dayu/api/v1/instance/my-instances', {}).then(res => {
        if (res) {
          this.instances = res.data;
          if (isRefresh) {
            ElMessage({
              message: '实例列表刷新成功',
              type: 'success',
              plain: true,
              duration: 500
            })
          }
        }
      }, res => {
        console.log(res);
      }).finally(() => {
        //
      });
    },
    getMigrateInstanceLabel(instance) {
      return `实例：${instance.name}，机房：${instance.regionDisplayName}`
    },
    getRegionList() {
      this.$httpUtil.get('/dayu/api/v1/region/list-region-options', {}).then(res => {
        if (res) {
          this.regionOptionsList = res.data;
        }
      }, res => {
        console.log(res);
      });
    },
    getInstanceRegionMigrateSwitch() {
      this.$httpUtil.get('/dayu/api/v1/instance-region-migrate/get-instance-region-migrate-switch', {}).then(res => {
        if (res) {
          this.instanceRegionMigrateSwitch = res.data;
        }
      }, res => {
        console.log(res);
      });
    },
    handleCancelMigrateRequest(idx, item) {
      this.$confirm('正在取消跨机房迁移实例申请，是否继续？', '提示', {
        confirmButtonText: '确认取消',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$httpUtil.urlEncoderPut('/dayu/api/v1/instance-region-migrate/cancel-migrate-request', {
          migrateLogId: item.id
        }).then(res => {
          ElNotification.success({
            title: '提示',
            message: "实例跨机房迁移请求已取消"
          });
          this.refresh();
        }, res => {
          console.log(res);
        });
      }).catch(() => {
        //
      });
    },
    handleClickAddMigrateRequest() {
      this.getInstanceList(false);
      this.getRegionList();
      this.addMigrateRequestDialogFormVisible = true;
    },
    handleDoAddMigrateRequest() {
      this.$httpUtil.jsonPost('/dayu/api/v1/instance-region-migrate/add-migrate-request', {
        sourceInstanceName: this.addInstanceRegionMigrateRequestForm.sourceInstanceName,
        targetRegion: this.addInstanceRegionMigrateRequestForm.targetRegion,
        remark: this.addInstanceRegionMigrateRequestForm.remark,
      }).then(res => {
        if (res) {
          this.$alert('实例跨机房迁移请求已成功提交，请耐心等待迁移', '提示', {
            confirmButtonText: '确定',
            callback: action => {
              this.addMigrateRequestDialogFormVisible = false;
              this.refresh();
            }
          });
        }
      }, res => {
        console.log(res);
      }).finally(() => {
        //
      });
    },
    getInstanceRegionMigrateLogs(isRefresh) {
      this.$httpUtil.get('/dayu/api/v1/instance-region-migrate/get-my-instance-region-migrate-log', {
        pageSize: 99
      }).then(res => {
        if (res) {
          this.instanceRegionMigrateLogs = res.data.data;
          if (isRefresh) {
            ElMessage({
              message: '实例跨机房迁移列表刷新成功',
              type: 'success',
              plain: true,
              duration: 500
            })
          }
        }
      }, res => {
        console.log(res);
      }).finally(() => {
        //
      });
    },
    getInstanceRegionMigrateLogStatistic() {
      this.$httpUtil.get('/dayu/api/v1/instance-region-migrate/get-instance-region-migrate-log-statistic', {
        pageSize: 99
      }).then(res => {
        if (res) {
          this.instanceRegionMigrateLogStatistic = res.data;
        }
      }, res => {
        console.log(res);
      }).finally(() => {
        //
      });
    },

    refresh() {
      this.reload();
    },

  }
}
</script>

<style scoped lang="less">
@import "@/assets/style/common.less";

.warn-tips {
  color: white;
  background-color: #E6A23C;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 4px;
}

#container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

#operation-board {
  display: flex;
  justify-content: space-between;
}
</style>
